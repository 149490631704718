





















































































































































































































































































































































































import { Component, Prop, Emit } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'
import TextInput from './TextInput.vue'
import TextInputTypeahead from './TextInputTypeahead.vue'
import RealtorInputTypeahead from './RealtorTextInputTypeahead.vue'
import DateInput from './DateInput.vue'
import SelectInput from './SelectInput.vue'
import ToggleInput from './ToggleInput.vue'
import FileInput from './FileInput.vue'
import ButtonSelectInput from './ButtonSelectInput.vue'
import ButtonMultiSelectInput from './ButtonMultiSelectInput.vue'
import ElevationInput from './ElevationInput.vue'
import MaterialInput from './MaterialInput.vue'
import CurrencyInput from './CurrencyInput.vue'
import ChildrenInput from './ChildrenInput.vue'
import CheckboxInput from './Checkbox.vue'
import CheckboxGroupInput from './CheckboxGroup.vue'
import TitleInput from './TitleInput.vue'
import DateRangeInput from './DateRangeInput.vue'
import RichTextField from './RichTextField.vue'
import MarketingTextInput from './MarketingTextInput.vue'
import { InputType } from '~/enums/inputType'
import ToggleButton from '~/components/shared/buttons/ToggleButton.vue'

@Component({
  components: {
    TextInput,
    TextInputTypeahead,
    RealtorInputTypeahead,
    DateInput,
    SelectInput,
    ToggleInput,
    FileInput,
    ButtonSelectInput,
    ButtonMultiSelectInput,
    ElevationInput,
    MaterialInput,
    CurrencyInput,
    ChildrenInput,
    CheckboxInput,
    CheckboxGroupInput,
    TitleInput,
    DateRangeInput,
    RichTextField,
    ToggleButton,
    MarketingTextInput,
  },
})
export default class Input extends BaseInputComponent {
  inputType: typeof InputType = InputType

  @Prop({ default: InputType.text }) type: InputType
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop() accept: string
  @Prop() documents: Document[]
  @Prop() prefix: string
  @Prop() suffix: string
  @Prop() description: string
  @Prop() columns: number
  @Prop({ default: (): any => null }) settings: any
  @Prop({ default: false }) singleSelection: boolean
  @Prop({ default: false }) singleSelectionNoDisable: boolean
  @Prop({ default: false }) checked: boolean
  @Prop() lookup: string
  @Prop({ default: () => ({}) }) preSelected: object
  @Prop({ default: '' }) heapId: string
  @Prop({ default: false }) soldOnToday: boolean
  @Prop({ default: false }) isVariance: boolean
  @Prop({ default: false }) isVarianceSelected: boolean
  @Prop({ default: '' }) model: string
  isVisible(): boolean {
    return this.settings?.visible !== undefined ? this.settings.visible : true
  }

  @Emit('input')
  handleInput(event: any): any {
    if (event && event.target) {
      if (event.target.type === 'text') {
        return event.target.value
      } else if (
        event.target.type === 'number' &&
        !isNaN(parseInt(event.target.value))
      ) {
        return +event.target.value
      } else if (event.target.type === 'checkbox') {
        return event.target.checked
      } else if (event.target.type === 'select-one') {
        if (event.target.value === 'true') {
          return true
        } else if (event.target.value === 'false') {
          return false
        }
        return event.target.value
      } else {
        return event.target.value
      }
    }

    return event
  }

  @Emit('validateField')
  validateField(): void {}

  @Emit('lookup')
  lookupSearch($event: any): any {
    return $event
  }

  @Emit('documents-changed')
  handleDocumentsChanged($event: Document[]): Document[] {
    return $event
  }

  @Emit('selectedCheckbox')
  onSelectedCheckbox($event: any): any {
    return $event
  }

  @Emit('unselectedCheckbox')
  onUnselectedCheckbox($event: any): any {
    return $event
  }

  @Emit('tagsChanged')
  tagsChanged(tags: string[]): string[] {
    return tags
  }

  @Emit('materialNextPage')
  materialNextPage($event: boolean): boolean {
    return $event
  }

  /**
   * @deprecated This function is not used sinse we do not handle additions
   */
  @Emit('createAddition')
  createAddition(): boolean {
    return true
  }

  @Emit('categoryChanged')
  categoryChanged(category: string): string {
    return category
  }

  @Emit('loadMoreItems')
  loadMoreItems(): void {}

  shouldShowSideRanges(): boolean {
    if (!this.settings || this.settings.showSideRanges === undefined) {
      return true
    }

    return this.settings.showSideRanges
  }

  materialsEnabled(): boolean {
    if (!this.settings || this.settings.enabled === undefined) {
      return true
    }

    return this.settings.enabled
  }

  @Emit('feature-list')
  processFeatureList($event: Array<string>): Array<string> {
    return $event
  }

  @Emit('onMaterialVarianceChanged')
  onMaterialVarianceChanged(value: boolean): boolean {
    return value
  }

  @Emit('onSearchMatrixMaterial')
  onSearchMatrixMaterial(data: any): any {
    return data
  }

  @Emit('marketingUserSelected')
  onMarketingUserSelected(value: any): any {
    return value
  }

  @Emit('onSearchPlan')
  onSearchPlan(search: string): string {
    return search
  }
}
